import { useMemo, useState } from 'react';
import { OneBlock, Box } from '@3dk/3style';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import convertRichText from '../../../../../utils/convertRichText';
import oneBlockRenderOptions from '../../../../../utils/richTextRenderOptions/oneBlockRenderOptions';
import getOneClickProducts from '../../../../../utils/getOneClickProducts';
import useOneClickAddToCart from '../../../../../utils/hooks/useOneClickAddToCart';
import { getLink } from '../../../../../components/LinkResolver';
import convertContentfulMarkupToElements from '../../../../../utils/ContentfulMarkup/ContentfulMarkupProcessor';
import LINK_TYPES from '../../../../../constants/linkTypes';

const makeStyles = () => ({
  bodyWrapper: {
    whiteSpace: 'pre-line',
    '& > *:first-child, &:last-child': {
      margin: '0',
    },
  },
});

const OneBlockEntry = ({
  id,
  header,
  subheader,
  contentText,
  links,
  clickable,
  anchorTag,
  splashLines,
  disclaimer,
  labels: tagLine,
  backgroundMedia,
  desktopBackgroundMedia,
  image,
  layout,
  setH1Tag,
}) => {
  const location = useLocation();
  const blockId = `campaign-${id}`;
  const [loadingLinks, setLoadingLinks] = useState({});
  const classes = useMemo(() => makeStyles(), []);
  const { oneClickAddToCart } = useOneClickAddToCart();

  const onAddToCart = (link, index) => {
    setLoadingLinks({ [index]: true });
    const { oneClickDevice, oneClickSubscriptions, oneClickAccessories, oneClickVas } = link || {};
    const oneClickProducts = getOneClickProducts(
      oneClickDevice,
      oneClickSubscriptions,
      oneClickAccessories,
      oneClickVas,
    );
    if (!oneClickProducts || oneClickProducts?.length === 0) {
      setLoadingLinks({ [index]: false });
    } else {
      oneClickAddToCart({ oneClickProducts, customOnCompleted: () => setLoadingLinks({ [index]: false }) });
    }
  };

  const actionLinks = (links || []).map((link, index) => {
    const { linkType, url, internalLink, anchorLink } = link || {};

    const resolvedLink = getLink(linkType, internalLink, url, {
      location,
      anchorLink,
    });

    const { Element, elementProps } = resolvedLink;
    return {
      component: linkType === LINK_TYPES.ONE_CLICK ? undefined : Element,
      href: elementProps?.href ? elementProps?.href : undefined,
      to: elementProps?.to ? elementProps?.to : undefined,
      label: link.text,
      onClick: linkType === LINK_TYPES.ONE_CLICK ? () => onAddToCart(link, index) : elementProps?.onClick,
      loading: !!loadingLinks[index],
    };
  });

  const layoutJson = useMemo(() => layout && JSON.parse(layout), [layout]);

  return (
    <OneBlock
      blockId={blockId}
      backgroundMedia={backgroundMedia}
      desktopBackgroundMedia={desktopBackgroundMedia}
      tagline={tagLine}
      splashLines={splashLines}
      link={clickable ? actionLinks[0] : undefined}
      disclaimer={disclaimer}
      layout={layoutJson}
      anchorTag={anchorTag}
      oneBlockTextProps={{
        title: header && convertContentfulMarkupToElements(header),
        subheader,
        body: contentText && <Box sx={classes.bodyWrapper}>{convertRichText(contentText, oneBlockRenderOptions)}</Box>,
        disclaimer,
        buttons: clickable ? undefined : actionLinks,
        layout: layoutJson,
        setH1Tag,
      }}
      oneBlockImageProps={image && { image: { ...image, alt: image.description } }}
    />
  );
};

OneBlockEntry.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  subheader: PropTypes.string,
  contentText: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  clickable: PropTypes.bool,
  anchorTag: PropTypes.string,
  splashLines: PropTypes.arrayOf(PropTypes.string),
  disclaimer: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  backgroundMedia: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  desktopBackgroundMedia: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
  }),
  layout: PropTypes.string,
  setH1Tag: PropTypes.bool,
};

OneBlockEntry.defaultProps = {
  header: null,
  subheader: null,
  contentText: null,
  links: null,
  clickable: false,
  anchorTag: null,
  splashLines: null,
  disclaimer: null,
  labels: null,
  backgroundMedia: null,
  desktopBackgroundMedia: null,
  image: null,
  layout: null,
  setH1Tag: false,
};

export default OneBlockEntry;
