import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { COUNTDOWN } from '../../app/routes/pageContainer/page/constants';
import Countdown from '../../components/Countdown';

const oneBlockRenderOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      const { sys, fields } = data.target || {};
      if (sys?.contentType?.sys?.id === COUNTDOWN) {
        return <Countdown asInlineEntry {...fields} />;
      }
      return node;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      const { sys, fields } = data.target || {};
      if (sys?.contentType?.sys?.id === COUNTDOWN) {
        return <Countdown {...fields} />;
      }
      return node;
    },
  },
};

export default oneBlockRenderOptions;
